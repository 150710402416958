@tailwind base;
@tailwind components;
@tailwind utilities;

.corner-border {
    position: relative;
}

.corner-border .corner {
    position: absolute;
    width: 20px;  /* Width of the corner border */
    height: 20px; /* Height of the corner border */
    background-color: transparent;
    border-color: #e1e0e0;
    border-style: solid;
}

.corner-border .top-left {
    top: 0;
    left: 0;
    border-width: 2px 0 0 2px;
}

.corner-border .top-right {
    top: 0;
    right: 0;
    border-width: 2px 2px 0 0;
}

.corner-border .bottom-left {
    bottom: 0;
    left: 0;
    border-width: 0 0 2px 2px;
}

.corner-border .bottom-right {
    bottom: 0;
    right: 0;
    border-width: 0 2px 2px 0;
}

.scale-on-click {
    @apply transition-transform duration-100 transform active:scale-95;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

